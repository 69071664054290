import plugin_vue3_BNmj9YdKOO from "/app/node_modules/.pnpm/@pinia+nuxt@0.4.11_rollup@3.29.5_typescript@5.0.2_vue@3.5.13_typescript@5.0.2_/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.mjs";
import components_plugin_KR1HBZs4kY from "/app/.nuxt/components.plugin.mjs";
import unhead_0nsVGK55o0 from "/app/node_modules/.pnpm/nuxt@3.3.1_@types+node@22.10.10_eslint@8.57.1_optionator@0.9.4_rollup@3.29.5_sass@1.59._0923d5768ba371af923cb0f2909a688a/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import vueuse_head_polyfill_ffdkfBEwhY from "/app/node_modules/.pnpm/nuxt@3.3.1_@types+node@22.10.10_eslint@8.57.1_optionator@0.9.4_rollup@3.29.5_sass@1.59._0923d5768ba371af923cb0f2909a688a/node_modules/nuxt/dist/head/runtime/plugins/vueuse-head-polyfill.js";
import router_2pDs4AYhxV from "/app/node_modules/.pnpm/nuxt@3.3.1_@types+node@22.10.10_eslint@8.57.1_optionator@0.9.4_rollup@3.29.5_sass@1.59._0923d5768ba371af923cb0f2909a688a/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import prefetch_client_cu3u9uFF4q from "/app/node_modules/.pnpm/nuxt@3.3.1_@types+node@22.10.10_eslint@8.57.1_optionator@0.9.4_rollup@3.29.5_sass@1.59._0923d5768ba371af923cb0f2909a688a/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import switch_locale_path_ssr_RJ3pvJ0xKX from "/app/node_modules/.pnpm/@nuxtjs+i18n@9.1.3_@vue+compiler-dom@3.5.13_eslint@8.57.1_rollup@3.29.5_typescript@5.0.2_vue@3.5.13_typescript@5.0.2_/node_modules/@nuxtjs/i18n/dist/runtime/plugins/switch-locale-path-ssr.js";
import ssg_detect_a1yrYS09N1 from "/app/node_modules/.pnpm/@nuxtjs+i18n@9.1.3_@vue+compiler-dom@3.5.13_eslint@8.57.1_rollup@3.29.5_typescript@5.0.2_vue@3.5.13_typescript@5.0.2_/node_modules/@nuxtjs/i18n/dist/runtime/plugins/ssg-detect.js";
import route_locale_detect_dwiFKPLp2I from "/app/node_modules/.pnpm/@nuxtjs+i18n@9.1.3_@vue+compiler-dom@3.5.13_eslint@8.57.1_rollup@3.29.5_typescript@5.0.2_vue@3.5.13_typescript@5.0.2_/node_modules/@nuxtjs/i18n/dist/runtime/plugins/route-locale-detect.js";
import i18n_41oFcDpJwS from "/app/node_modules/.pnpm/@nuxtjs+i18n@9.1.3_@vue+compiler-dom@3.5.13_eslint@8.57.1_rollup@3.29.5_typescript@5.0.2_vue@3.5.13_typescript@5.0.2_/node_modules/@nuxtjs/i18n/dist/runtime/plugins/i18n.js";
import plugin_zQq2FUuJyO from "/app/node_modules/.pnpm/nuxt3-meta-pixel@1.0.9_rollup@3.29.5/node_modules/nuxt3-meta-pixel/dist/runtime/plugin.mjs";
import chunk_reload_client_s2z8bOnIat from "/app/node_modules/.pnpm/nuxt@3.3.1_@types+node@22.10.10_eslint@8.57.1_optionator@0.9.4_rollup@3.29.5_sass@1.59._0923d5768ba371af923cb0f2909a688a/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import navbar_qw38FjZisw from "/app/plugins/navbar.ts";
import ssr_plugin_zuTfHHKS9J from "/app/node_modules/.pnpm/@vueuse+nuxt@9.13.0_nuxt@3.3.1_@types+node@22.10.10_eslint@8.57.1_optionator@0.9.4_roll_5487939953a6b718106ab9e25bb40536/node_modules/@vueuse/nuxt/ssr-plugin.mjs";
import aos_client_rDSYU13oOX from "/app/plugins/aos.client.ts";
import fontawesome_klhsrycjcK from "/app/plugins/fontawesome.js";
import i18n_VfGcjrvSkj from "/app/plugins/i18n.ts";
import vue_gtag_client_fbnXi0Aqdd from "/app/plugins/vue-gtag.client.js";
import vueGoogleMaps_8xmoAwKZvr from "/app/plugins/vueGoogleMaps.ts";
export default [
  plugin_vue3_BNmj9YdKOO,
  components_plugin_KR1HBZs4kY,
  unhead_0nsVGK55o0,
  vueuse_head_polyfill_ffdkfBEwhY,
  router_2pDs4AYhxV,
  prefetch_client_cu3u9uFF4q,
  switch_locale_path_ssr_RJ3pvJ0xKX,
  ssg_detect_a1yrYS09N1,
  route_locale_detect_dwiFKPLp2I,
  i18n_41oFcDpJwS,
  plugin_zQq2FUuJyO,
  chunk_reload_client_s2z8bOnIat,
  navbar_qw38FjZisw,
  ssr_plugin_zuTfHHKS9J,
  aos_client_rDSYU13oOX,
  fontawesome_klhsrycjcK,
  i18n_VfGcjrvSkj,
  vue_gtag_client_fbnXi0Aqdd,
  vueGoogleMaps_8xmoAwKZvr
]